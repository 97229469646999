import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { AutoUnsubscribable } from '@thg-procure-team/procure-common-ui';
import { ThgcMaintenanceService } from '@thg-procure-team/procure-common-ui/platform/maintenance';

@Component({
  selector: 'thg-root',
  template: `
    <thgc-maintenance *ngIf="isMaintenance$ | async; else app"></thgc-maintenance>

    <ng-template #app>
      <thgc-toast></thgc-toast>
      <thgc-router-resolve-progressbar></thgc-router-resolve-progressbar>
      <thg-layout-home *ngIf="canDisplay$ | async"></thg-layout-home>
    </ng-template>
  `,
})
export class AppComponent extends AutoUnsubscribable implements OnInit {
  public canDisplay$: Observable<boolean>;
  public isMaintenance$: Observable<boolean>;

  constructor(
    private readonly thgcMaintenanceService: ThgcMaintenanceService,
    private readonly userService: UserService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isMaintenance$ = this.thgcMaintenanceService.isMaintenance$;
    this.canDisplay$ = this.userService.user$.pipe(map((user) => !!user));
  }
}
