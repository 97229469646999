<div class="container">
  <div class="card mt-5">
    <div class="card-header p-4 text-center">
      <h2 class="card-title mb-3">
        <i class="dripicons-search mr-2"></i>
        {{ notFoundTitle }}
      </h2>
      <p class="m-0">
        <b>{{ notFoundMessage }}</b>
      </p>
    </div>
    <footer class="card-footer text-center">
      <div class="btn btn-success"
           (click)="onGoToHomeClick()">
        {{ homePageLabel }}
      </div>
    </footer>
  </div>
</div>
