import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AutoUnsubscribable } from '@thg-procure-team/procure-common-ui/platform/domain';
import { PendingActionsApiService } from '@thg-procure-team/procure-common-ui/business/pending-actions';
import { WarehouseEntity } from '@thg-procure-team/procure-common-ui/business/warehouse';

import { WarehouseStateService } from '@procure-warehouse/feature/warehouse/services/warehouse-state.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationStateService extends AutoUnsubscribable {
  private _pendingActionsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly pendingActionsCount$: Observable<number> = this._pendingActionsCount$.asObservable();

  constructor(
    private readonly pendingActionsApiService: PendingActionsApiService,
    private readonly warehouseState: WarehouseStateService,
  ) {
    super();
  }

  public loadDashboardNotifications(): void {
    if (this.warehouseState.selectedWarehouse) {
      this.subSink.sink = this.pendingActionsApiService
        .getPendingActionCount({ warehouseId: this.warehouseState.selectedWarehouse.id })
        .subscribe(
          (pendingActionCount) => this._pendingActionsCount$.next(pendingActionCount),
          () => this._pendingActionsCount$.next(0),
        );
    }
  }
}
