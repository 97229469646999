import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WarehouseEntity } from '@thg-procure-team/procure-common-ui/business/warehouse';

@Injectable({
  providedIn: 'root',
})
export class WarehouseStateService {
  private _selectedWarehouse$: BehaviorSubject<Partial<WarehouseEntity>> = new BehaviorSubject<
    Partial<WarehouseEntity>
  >(null);

  public selectedWarehouse$: Observable<Partial<WarehouseEntity>> = this._selectedWarehouse$.asObservable();

  set selectedWarehouse(warehouse: Partial<WarehouseEntity>) {
    this._selectedWarehouse$.next(warehouse);
  }

  get selectedWarehouse() {
    return this._selectedWarehouse$.getValue();
  }
}
