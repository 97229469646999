import { Observable } from 'rxjs';
import { filter, skip, startWith } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AnnouncementEntity,
  NavigationMenuItem,
  RightSidebarContentType,
  ThemeMenuItem,
} from '@thg-procure-team/procure-common-ui/platform/layout';
import { ThgcLocalizationService } from '@thg-procure-team/procure-common-ui/platform/localization';
import { SubSink } from '@thg-procure-team/procure-common-ui/platform/utils';
import { UserEntity, UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { NavigationStart, Router } from '@angular/router';
import { ColorType } from '@thg-procure-team/procure-common-ui/platform/theme';
import { AnnouncementInboxService } from '@thg-procure-team/procure-common-ui/business/announcement-inbox';

import { NavigationStateService } from '@procure-warehouse/core/store/navigation-state.service';
import { WarehouseStateService } from '@procure-warehouse/feature/warehouse/services/warehouse-state.service';

@Component({
  selector: 'thg-layout-home',
  templateUrl: './layout-home.component.html',
  styleUrls: ['./layout-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHomeComponent implements OnInit, OnDestroy {
  public isLeftSidebarOpen: boolean;
  public isRightSidebarOpen: boolean;
  public rightSidebarContentType: RightSidebarContentType;
  public navigationMenu: NavigationMenuItem[];
  public themeMenu: ThemeMenuItem[];
  public user$: Observable<UserEntity>;
  public announcements: Observable<AnnouncementEntity[]>;
  public unreadAnnouncementCount: Observable<number>;
  private readonly subSink: SubSink = new SubSink();

  constructor(
    private readonly thgcLocalizationService: ThgcLocalizationService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly announcementInboxService: AnnouncementInboxService,
    private readonly navigationStateService: NavigationStateService,
    private readonly warehouseStateService: WarehouseStateService,
  ) {}

  public ngOnInit(): void {
    this.user$ = this.userService.user$;
    this.announcements = this.announcementInboxService.announcements$;
    this.unreadAnnouncementCount = this.announcementInboxService.unreadAnnouncementCount;
    this.setupMenuItems();
    this.watchSelectedWarehouseChange();
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public onLeftSidebarToggle(isLeftSidebarOpen: boolean): void {
    this.isLeftSidebarOpen = isLeftSidebarOpen;
  }

  public onRightSidebarToggle(isRightSidebarOpen: boolean, type?: RightSidebarContentType): void {
    this.isRightSidebarOpen = isRightSidebarOpen;
    this.rightSidebarContentType = type;
  }

  public onLogout(): void {
    this.userService.logout();
  }

  public onLocaleChange(locale: string): void {
    this.userService.setLanguage(locale);
  }

  public onThemeChange(theme: string): void {
    this.userService.setTheme(theme);
  }

  public toggleAnnouncementRead(announcement: AnnouncementEntity): void {
    this.announcementInboxService.toggleAnnouncementRead(announcement);
  }

  private setupMenuItems(): void {
    this.subSink.sink = this.thgcLocalizationService.localeChanged$.subscribe(() => {
      this.themeMenu = [
        { label: this.thgcLocalizationService.translate('appSettings.darkThemeLabel'), key: 'dark' },
        { label: this.thgcLocalizationService.translate('appSettings.lightThemeLabel'), key: 'light' },
      ];
      this.navigationMenu = [
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoardTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoard'),
          icon: 'dripicons-bell',
          link: '/dashboard',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.setupTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.configurationLabel'),
          icon: 'dripicons-gear',
          link: '/configuration',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.reservedSlotsLabel'),
          icon: 'dripicons-duplicate',
          link: '/reserved-slots',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.reportTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.deliveryBookingLabel'),
          icon: 'uil uil-truck',
          link: '/delivery-booking',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.deliveryCollectionsLabel'),
          icon: 'uil uil-archive',
          link: '/delivery-collections',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.pendingAsnsLabel'),
          icon: 'uil uil-clock-three',
          link: '/pending-asns',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.gatehouseTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.checkInLabel'),
          icon: 'mdi mdi-calendar-check-outline',
          link: '/check-in',
        },
      ];
      this.watchRouterNavigation();
      this.changeDetectorRef.markForCheck();
    });
  }

  private watchSelectedWarehouseChange() {
    this.subSink.sink = this.warehouseStateService.selectedWarehouse$.pipe(skip(1)).subscribe(() => {
      this.updateDashboardBadge();
    });
  }

  private watchRouterNavigation(): void {
    this.subSink.sink = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        startWith([]),
      )
      .subscribe(() => this.updateDashboardBadge());
  }

  private updateDashboardBadge(): void {
    const dashboardIndex = 1;
    this.navigationStateService.loadDashboardNotifications();
    this.subSink.sink = this.navigationStateService.pendingActionsCount$
      .pipe()
      .subscribe((pendingActionsCount) => {
        const dashboardNavigationItem = this.navigationMenu[dashboardIndex];
        if (pendingActionsCount && pendingActionsCount > 0) {
          const badgeText = pendingActionsCount > 99 ? '99+' : pendingActionsCount.toString();
          dashboardNavigationItem.badge = { colorType: ColorType.DANGER, text: badgeText };
        } else {
          dashboardNavigationItem.badge = null;
        }

        this.navigationMenu = [...this.navigationMenu];
      });
  }
}
